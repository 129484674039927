import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { registerServiceWorker } from './utils/serviceWorkerUtils'

// console.log(publicVapidKey);
const publicVapidKey=process.env.REACT_APP_PUBLIC_VAPID_KEY


    // console.log(publicVapidKey);
    

  registerServiceWorker(()=>{},()=>{},publicVapidKey);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();